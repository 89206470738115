define('modules/news/basic/js/base.js',["app/config", "jquery", "app/util", "templates"], function (config, $, util, templates) {
    return {
        name: 'news',
        selectors: false,
        remoteData: [config.urls.news],

        initialize: function (data) {
            var $container = $('.newsContainer');

            $.each(data, $.proxy(function (index, record) {
                // Add each news post to the main container
                var tabSelector = "tab-" + record.category;
                var $newsTypeContainer = $($container.find("." + tabSelector));
                if($newsTypeContainer.length == 0){
                    $newsTypeContainer = $("<div class='tab " + tabSelector + "'></div>");
                    $container.append($newsTypeContainer);
                    $container.find(".tabsNavigation").append("<a href='#' data-tabsselector='" + tabSelector + "'>" + record.category + "</a>");
                }

                $newsTypeContainer.append(templates.newspost(record));
                // Set the first three news posts on the menu main
                if (index < 3) {
                    $('#mainMenu .newsContent').append(templates.newsmenu(record));
                }
                // Set the first four titles on the news page
                if (index < 4) {
                    var titleEl = $('<div class="col-md-1 col-xs-3"></div>');
                    var date = new Date(record.date);
                    titleEl.html('<div class="titleWrap"><span>' + (date.toDateString().split(' ')[1]) + '</span><span>' + (date.getDate()) + '</span><span>' + String(date.getFullYear()) + '</span></div>');
                    titleEl.attr('data-news', record.id);
                    $('#news .newsTitleWrap').append(titleEl);
                }
            }, this));
            $('#news .newsTitleWrap > div').first().addClass('active');

            // Primary click handler for read more button
            $('.newsContainer .readMoreBtn').click(function (e) {
                e.preventDefault();
                //$(this).parents('article').find('.postContent').toggle('fade',{
                //    direction: 'up'
                //},!$(this).hasClass('btnClose'));
                // TODO refactor modules for configs
                if ($(this).hasClass('js-switch-icon')) {
                    $(this).parents('article').find('.postContent').slideToggle(300, function () {
                    }).toggleClass('open');
                    $(this).siblings('.postDescription').toggle();
                    $(this).html($(this).hasClass('btnClose') ? 'Read' : '<i class="material-icons">close</i>');
                    $(this).toggleClass('btnClose');
                }
                else if ($(this).hasClass('js-switch-icon-mobile')) {
                    $(this).parents('article').find('.postContent').slideToggle(300, function () {
                    }).toggleClass('open');
                    $(this).siblings('.postDescription').toggle();
                    $(this).html($(this).hasClass('btnClose') ? '<i class="material-icons">add_circle_outline</i>' : '<i class="material-icons">remove_circle_outline</i>');
                    $(this).toggleClass('btnClose');
                }
                else {
                    $(this).parents('article').find('.postContent').slideToggle(300, function () {
                    }).toggleClass('open');
                    $(this).siblings('.postDescription').toggle();
                    $(this).text($(this).hasClass('btnClose') ? 'Read' : 'Close');
                    $(this).toggleClass('btnClose');
                }

                return false;
            });

            $container.on("click", ".tabsNavigation a", function(ev){
                ev.preventDefault();

                var $target = $(ev.target);
                var tabsSelector = $target.data("tabsselector");

                if(tabsSelector){
                    $container.find('.tabsNavigation .active').removeClass('active');
                    $target.addClass('active');
                    $container.find(".tab").removeClass("activeTab");
                    $container.find(".tab." + tabsSelector).addClass("activeTab");
                }
            })

            // TODO refactor config etc
            $('.newsbox .article-date').each(function(){
                var text = $.trim($(this).text()),
                    word = text.split(' '),
                    str = "";
                //alert(word[1]);
                $.each( word, function( key, value ) {
                    //alert( key + ": " + value );
                    if(key != 0) { str += " "; }
                    str += "<span>" + value + "</span>";
                });
                $(this).html(str);
            });


            // Handle news hashtag
            // TODO - This needs to be refactored into a route method
            if (location.hash.length >= 2) {
                // Fetch the selected news item
                var newsItem = $('#news [data-news="' + location.hash.substr(1) + '"]');
                if (newsItem.length > 0) {
                    newsItem.find('.readMoreBtn').trigger('click');
                    var newsScrollBody = ( $(window).width() >= 768 ? $('.newsPostCol') : $(window) );
                    $(window).scrollTop(0);
                    newsScrollBody.scrollTop(newsItem.offset().top - $('#mainNavbar').height() - 20);
                }
            }

            $('body').trigger('pagenavupdate');

            var tabsNavigationLinks = $container.find(".tabsNavigation a");
            tabsNavigationLinks.first().trigger("click");
            if(tabsNavigationLinks.length == 1){
                tabsNavigationLinks.hide();
            }
        }
    }
});
