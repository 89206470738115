// This is the base/template version of the hooks file override used in projects folder
// Copy this to projects/[projectname]/js/hooks.js and then add logic to the below methods as desired
// TODO - Turn off debug statements
define('hooks',['jquery','app/util'],function($,util){
    var methods = {
        onDocumentReady: function() {
            //console.debug('Project hook - document ready.')

            $('.btn.btn-learn-more').click(function(e){
                e.preventDefault();
                $('html,body').animate({
                    scrollTop: $('.home-process').offset().top - 100
                },1000);
            });

            // TODO move
            $('.collapse').collapse();

            // This is a bug in bs 3.1.1 collapse https://github.com/twbs/bootstrap/issues/7213
            // our framework should be updated with the bug fix
            // this is specific to our home accordion
            $(document).find('#homeAccordion').on('click.bs.collapse.data-api', '[data-toggle=collapse]', function (e) {

                $('#homeAccordion .panel').removeClass('active');
                $(e.target).parent('.panel').addClass('active');
                $('#homeAccordion .collapse').removeClass('in');

                if ($(e.target).hasClass('collapsed')){
                    $(e.target).parent('.panel').removeClass('active');
                }

                setTimeout(function(){
                    $('html, body').animate({
                        scrollTop: $(e.target).offset().top - 200
                    }, 500);
                }, 700);
            });
        },
        onAppInitialized: function() {
            console.debug('Project hook - app initialized.')
            // Start routing and trigger window resize for skrollr
            console.log('app init');
            $(window).hashchange().trigger('resize'); // TODO - Relocate this, possibly to router/nav
        },
        onModulesInitialized: function() {
            //console.debug('Project hook - modules initialized.');
            console.log('mod init');
            // TODO - This method is not yet implemented
        }
    };
    $(document).ready(methods.onDocumentReady);
    return methods;
});

