define('modules/judges/expander/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.livequery", "app/features/expandergrid"], function (module, config, $, util, templates) {
    return new module({
        name: 'judges.expander',
        selectors: '.judgePageWrap, .judgeSection',
        remoteData: [config.urls.judges],
        routes: [{
            route: 'judges/(.*)',
            fn: function (hash) {
                $('#judges .judgebox[data-judge="' + hash.split('/')[1] + '"]').trigger('click');
            },
            extra: true,
            delay: 2500
        }],


        config: {
            rightPanelLayout: true,
            showTypeBar: false,
            useFallbackMethods: false
        },

        initialize: function (data) {
            this.data = (data || this.data);
            this.renderTemplates(this.data);
            this.clickHandlers(this.data);


        },

        methods: {
            renderTemplates: function (data) {
                var judgeGroups = [],
                    templateData = {
                        judgeGroups: []
                    };
                $.each(data, function (index, record) {
                    if (!judgeGroups[record.type]) {
                        judgeGroups[record.type] = {
                            name: record.type,
                            title: record.shortDescription,
                            cms: record.cms,
                            desc: record.desc,
                            descTitle: record.descTitle,
                            records: []
                        };
                    }
                    judgeGroups[record.type].records.push(record);
                });
                for (var key in judgeGroups) {
                    templateData.judgeGroups.push(judgeGroups[key]);
                }
                this.templateData = templateData;
                $('.judgePageWrap').append(templates.judgespage({
                    data: templateData,
                    config: this.config
                }));

                $('.judgePageWrap .judgeGroup').each(function(){
                    // TODO on window resize by col number
                    var divs = $(this).find('.inner-col');
                    if($(window).width() < 767) {
                      for (var i = 0; i < divs.length; i += 3) {
                          divs.slice(i, i + 3).wrapAll('<div class="row"></div>');
                      }
                    }
                    else {
                      for (var i = 0; i < divs.length; i += 5) {
                          divs.slice(i, i + 5).wrapAll('<div class="row"></div>');
                      }
                    }
                });

                // top buttons scroll to section
                $('.introBtnContainer .btn').click(function(e){
                    e.preventDefault();
                    var el = $(this),
                        group = $(el).attr('data-judgegroup');
                    // TODO - Convert this to use util methods and detect scroll parent
                    $('html,body').animate({
                        scrollTop: $('.judgeGroup[data-judgegroup="'+group+'"]').offset().top - 100
                    },500);
                });
            },

            getActiveColumn: function () {
                return $('.expanderGrid').find('.inner-col[data-active="true"]').first();
            },

            clickHandlers: function (data) {
                var w = $(window).width();

                // TODO this seemed better suited within the new module, however revisit
                $(this.getSelectors()).find('.inner-col').on('click', function (e) {

                    // hide open detail row
                    //$('.expanderGrid').find('.expanderNav i.close').not($(this).parents('.expanderGrid').find('.expanderNav i.close')).trigger('click');

                    if($('.expanderGrid').find('.detailRow').not($(this).parents('.expanderGrid').find('.detailRow')).hasClass('js-detailRow-open')){
                        $('.expanderGrid').find('.detailRow.js-detailRow-open .expanderNav i.close').trigger('click');
                    }

                    // Set the active attribute on clicked column
                    $(this).parents('.expanderGrid').find('.inner-col').attr('data-active', false);

                    // append up arrow to grid border
                    $(this).parents('.expanderGrid').find('.right-arrow').not($(this).parents('.expanderGrid').find('.right-arrow.next')).remove();
                    $(this).attr('data-active', true).append('<i class="right-arrow"></i>');

                    // Update and move description row
                    var parentRow = $(this).parents('.row').first();
                    $(this).parents('.expanderGrid').find('.detailRow .expanderContent').html($(this).find('.innerSwap').html());
                    if ($(this).width() * 2 > $(this).parents('.expanderGrid').width()) {
                        $(this).parents('.expanderGrid').find('.detailRow').insertAfter($(this));
                    } else {
                        $(this).parents('.expanderGrid').find('.detailRow').insertAfter(parentRow);
                    }


                    $(this).parents('.expanderGrid').find('.detailRow').fadeIn({
                        complete: $.proxy(function () {
                            $(this).trigger('mouseenter'); // Trigger hover for repositioning as DOM moves
                        }, this)
                    }).addClass('js-detailRow-open');




                    setTimeout(function(){
                        $('html, body').animate({
                            scrollTop: $('.inner-col[data-active="true"]').offset().top - 100
                        }, 500);
                    }, 500);

                    var innerColCount = 0;
                    $(e.target).parents('.expanderGrid').find('.inner-col').each(function (e) {
                        innerColCount++;
                    });

                    $(this).parents('.expanderGrid').find('.total-count').text(innerColCount);

                    var innerColIndex = $(this).closest('.expanderGrid').find('.inner-col').index( this );
                    $(this).parents('.expanderGrid').find('.current-count').text(innerColIndex + 1);


                });

                // nav clicks
                $(this.getSelectors()).find('.expanderNav i').on('click', $.proxy(function (e) {
                    var gridCount = 0;
                    $(e.target).parents('.expanderGrid').find('.inner-col').each(function (e) {
                        gridCount++;
                    });

                    // minus one since zero index
                    var gridCountIndex = gridCount - 1;

                    var activeIndex = $(e.target).parents('.expanderGrid').find('.inner-col').index($(this.getActiveColumn($(e.currentTarget).parents('.expanderGrid'))));

                    if ($(e.target).hasClass('next')) {
                        // if we are at the end of the grid items
                        // set index to -1 to land on 0 on click next
                        if (activeIndex == gridCountIndex) {
                            activeIndex = -1;
                        }
                        $(e.target).parents('.expanderGrid').find('.inner-col').eq(activeIndex + 1).trigger('click');
                    }
                    else if ($(e.target).hasClass('prev')) {
                        $(e.target).parents('.expanderGrid').find('.inner-col').eq(activeIndex - 1).trigger('click');
                    }
                }, this));

                // close click
                $(this.getSelectors()).find('.expanderNav i.close').on('click', $.proxy(function (e) {
                    $(e.target).parents('.expanderGrid').find('.right-arrow').not($(this).parents('.expanderGrid').find('.right-arrow.next')).remove();
                    $(e.target).parents('.expanderGrid').find('.detailRow').fadeOut(300).removeClass('js-detailRow-open');
                    $('.detailRow .video-id iframe').attr('src', '');
                    $('.inner-col').removeAttr('data-active');
                    $('body').removeClass('locked');
                    $('.navbarWrap').removeClass('hidden');
                }, this));
            }
        }
    });
});

