define('modules/timeline/advanced/js/base.js',["app/module","app/config","jquery","app/util","templates","bootstrap/tooltip","bootstrap/carousel"], function(module,config,$,util,templates) {
    return new module({
        name: 'timeline',
        selectors: '.timelineSection',
        remoteData: [(config.urls.timeline)],

        routes: [{
            route: '(.*|home|timeline)',
            fn: 'refreshTimeline',
            extra: true,
            delay: 3000
        },{
            route: '(.*|home|timeline)',
            fn: function() {
                // Show the active tooltip
                $('.timelinePoint.active').click();
            },
            extra: true,
            delay: 5000
        }],

        config: {
            staggerPoints: 3,
            shortMonths: true,
            timelineWidth: '300%',
            advancedCards: false,
            timelineLeftOffset: false
        },

        initialize: function(data) {

            // Create timestamps for each record
            $(data).each(function(index,record){
                record.timestamp = new Date(record.date).getTime();
            });

            // Sort by timestamps
            data = data.sort(util.dynamicSort('timestamp','id'));

            // Bounding
            var startMonth = new Date(new Date(data[0].timestamp).getFullYear(), new Date(data[0].timestamp).getMonth(), 1, 0, 0, 0),
                endMonth = new Date(new Date(data[data.length - 1].timestamp).getFullYear(), new Date(data[data.length - 1].timestamp).getMonth() + 1, 0, 0, 0, 0),
                startExtra = data[0].timestamp - startMonth.getTime();

            //Let the range be the full period
            var msCount = (endMonth.getTime() - startMonth.getTime()),
                msIncrement = 100/msCount;

            // populate start/end periods and set the active record
            var now = new Date().getTime();
            var isActiveSet = false;
            $.each(data,function(i,item){
                if(i == 0) {
                    item.startPeriod = true;
                } else if(data[i-1].period != item.period) {
                    data[i-1].endPeriod = true;
                    item.startPeriod = true;
                }
                if(i == data.length-1) {
                   item.endPeriod = true;
                    if(!isActiveSet){
                        item.active = true;
                    }
                }

                if(!isActiveSet && now < item.timestamp){
                    item.active = true;
                    isActiveSet = true;
                }
            });
            // Render the template
            $(this.getSelectors()).html($(templates.timeline({
                timelinePoints: data,
                msIncrement: msIncrement,
                start: data[0].timestamp
            })));

            // Give the timeline gutters
            if($(window).width() > 1024 && !this.getConfig().advancedCards) {
            	$('.timeline').css({
                	//width: this.getConfig().timelineWidth || '100%', //width: (100 - ((startExtra + endExtra) * msIncrement)) + '%',
                	'margin-left': ((startExtra*msIncrement)*2)+'%'
            	});
            }

            // Init tooltips
            $('.timelinePoint').tooltip();

            // Add the indicator for today's date and show the tooltip
            var nowPosition = (new Date().getTime()-data[0].timestamp)*msIncrement;
            $('<div class="timelineNow"><i class="material-icons">place</i></div>').attr('data-title','Today').css('left',nowPosition+'%').appendTo($('.timeline'));
            $('.timelineNow').tooltip({
                template: '<div class="tooltip tooltipNow"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
            });

            // Tooltip listeners
            $('.timelinePoint, .timelineNow').on('show.bs.tooltip',function(){
                if(!$(this).hasClass('active')) {
                    $('.timelinePoint.active').tooltip('hide');
                }
            }).on('hide.bs.tooltip',function(){
                if($(this).hasClass('active')) {
                    return false; // Do not allow closing on currently viewed point
                } else {
                    if(!$('.timelinePoint.active').next().hasClass('in')) {
                        $('.timelinePoint.active').tooltip('show'); // Ensure the active point is reshown
                    }
                }
            });

            // Timeline point handlers
            $('.timelinePoint').on('click',$.proxy(function(e){
                e.preventDefault();
                this.handleTimelinePointClick(e);
            },this));

            // Timeline nav handlers
            $('.timelineNav').on('click',$.proxy(function(e){
                e.preventDefault();
                this.handleTimelineNav(e);
            },this));

            // Update the init Period information
            $('.timeline-period h3').html( data[0].period );
            $('.timeline-period h6').html( data[0].periodlength );
            $('.timeline .connectLine[data-period="'+data[0].period+'"]').addClass('highlight');


            //===== Init Calendar =====//

            var firstMonth  = new Date(data[0].timestamp),
            // Pointer to the DOM
                calendar        = $('.timeline-calendar ul'),
            // Array of month name strings
                monthNames      = ['January','February','March','April','May','June','July','August','September','October','November','December'],
            // Counts the number of months between our two dates
                monthCount      = 0;

            // Fetch the first date in the timestamp
            var curMonth = firstMonth;


            // For each month...
            while ( curMonth < endMonth ) {

                // Fetch the month's name
                var curMonthName = monthNames[curMonth.getMonth()];

                // Get any timeline points and periods that are inside this month
                var periods = $.map(data,function(v){
                    var month = new Date(v.timestamp).getMonth();
                    if(month == curMonth.getMonth()) {
                        return v.period;
                    } else {
                        return null;
                    }
                });

                // Append the month to our calendar
                calendar.append('<li data-period="'+periods.join(',')+'">' + (this.getConfig().shortMonths ? curMonthName.substr(0,3) : curMonthName) + '</li>');

                // Increase the current month by 1
                curMonth.setMonth( curMonth.getMonth() + 1 );
                // Increase our month counter
                monthCount++;
            }

            // Finally, adjust the width of our month names evenly
            var borderWidth = $('.timeline-calendar li').css('border-right-width').replace("px", "");

            $('.timeline-calendar li').width( (100 / monthCount) - (borderWidth) + '%' );

            // timeout fixes the bug of the first tool tip
            // showing up in the wrong place
            if (($('.timeline').css('margin-left') <= '1%' ) && ($('.timelineContainer .timeline-bottom').css('width') == '100%' )) {
            	$('.timelinePoint.first').css('left','1%');
            }

            this.timelinePeriodHover();
        },

        onRender: function() {
            // These two onRender methods below will ensure the firing of refreshTimeline after initialize
            $(window).smartresize($.proxy(this.refreshTimeline,this));
            util.setVisibleInterval($(this.getSelectors()).find('.timeline'),$.proxy(this.refreshTimeline,this));
        },

        methods: {
            handleTimelinePointClick: function (e) {
                var pointEl = $(e.currentTarget);
                $('.timeline .timelinePoint').removeClass('active');
                pointEl.addClass('active');
                $('.timeline .timelinePoint').tooltip('hide');
                $('.timeline-bottom .tooltip').hide();
                var container_width = $('.timeline-bottom').width();
                $('.timeline').css('position', 'relative');
                if(this.getConfig().advancedCards) {
                    if (this.getConfig().timelineLeftOffset && $(window).width() > 768) {
                        $('.timeline').css({ "left": (this.getConfig().timelineLeftOffset) });
                    }
                    else {
                        $('.timeline').animate({
                            left: (($('.timeline-bottom').width()/2)-(parseFloat(pointEl.css('left'))))
                        });
                    }

                } else {
                    $('.timeline').animate({
                        left: (-parseFloat(pointEl.css('left')) * 0.9)
                    });
                }
                $(this.getSelectors()).find('.timelineBackground').css({
                    'background-position': (parseFloat(pointEl.css('left')) * .4)+'px 0px' // TODO - Refine the movement amount
                });
                $('.timelineNavCount').text($('.timelinePoint').index(pointEl)+1);
                setTimeout(function(){
                    pointEl.attr('data-trigger','manual').tooltip('show');
                },410);

                if (this.getConfig().advancedCards) {
                    $('#timelineInfo').carousel($('.timelinePoint').index($('.timeline .timelinePoint.active')));
                }
            },

            handleTimelineNav: function(e) {
                var direction = $(e.currentTarget).hasClass('right'),
                    currentIndex = $('.timelinePoint').index($('.timeline .timelinePoint.active'));
                if((currentIndex+(direction ? 1 : -1)) >= $('.timelinePoint').length) {
                    $('.timelinePoint').first().trigger('click');
                } else {
                    $('.timelinePoint').eq(currentIndex+(direction ? 1 : -1)).trigger('click');
                }
            },

            setTimelinePointPositions: function() {
                $('.timeline .timelinePoint').each($.proxy(function(i,v){
                    var offset = ((i+2) % this.getConfig().staggerPoints);
                    if(i > 0) {
                        if(!$(v).hasClass('last')) {
                            // TODO - Overlap detection is not 100% dynamic, since the timeline length is determined independently and with max widths
                            // We must always assume dates will be realistically spread out depending on the total period, otherwise we need to come up with a fully recursive overlap detection system
                            var overlap = util.checkElementOverlap($('.timeline .timelinePoint').eq(i+1),$(v));
                            if(overlap) {
                                $('.timeline .timelinePoint').eq(i).css('margin-left', '-26px');
                                if($('.timeline .timelinePoint').eq(i-1).css('margin-left') == '-26px') {
                                    $('.timeline .timelinePoint').eq(i-1).css('margin-left','-52px');
                                }
                            }
                        }
                    }
                    $(v).addClass('offsetTop'+offset);
                },this));
            },

            setTimelinePeriodTitles: function() {
                $('.timelinePeriod').each(function(i,v){
                    var width = 0;
                    $(v).find('.connectLine').each(function(ii,vv){
                        width += $(vv).width();
                        if(ii == 0) {
                            $(v).find('.periodTitle').css('left',parseInt($(vv).css('left'))+20);
                        }
                    });
                    $(v).find('.periodTitle').width(width);
                });

                // first hide any small ones
                $('.timelinePeriod .periodTitle').each(function(){
                    if ($(this).width() < 100) {
                        $(this).addClass('short');
                    }
                });
            },

            refreshTooltipPositions: function() {
                $('.timeline-bottom .tooltip').hide();
                $('.timelinePoint').tooltip('enable');
                $('.timeline-bottom .tooltip').tooltip('hide');
            },

            refreshTimeline: function() {
                if(this.isReady() && $(this.getSelectors()).find('.timeline').is(':visible')) {
                    // TODO - Refine the timeout handling in this section, this is due to the animation running on the timeline resize
                    this.setTimelinePointPositions();
                    this.setTimelinePeriodTitles();
                    setTimeout($.proxy(function(){
                        this.setTimelinePointPositions();
                        this.setTimelinePeriodTitles();
                        setTimeout($.proxy(this.refreshTooltipPositions,this),500);
                        this.timelineDrop();
                    },this),350);
                }
            },

            timelineDrop: function() {
                var dropShadowWidth = 0;
                $('.periodTitle').each(function(){
                    var periodTitleWidth = $(this).width();
                    dropShadowWidth = dropShadowWidth + periodTitleWidth;
                });

                if($(window).width() > 768)  {
                    $('.timelineDropShadow').css('width',dropShadowWidth + 25);
                }
                else {
                    $('.timelineDropShadow').css('width',dropShadowWidth + 15 );
                }
            },
            timelinePeriodHover: function() {
                // TODO we'll have to check this on different points of the timeline, works fine on the end
                var periodTitleWidth;
                var connectLineWidth;
                var newWidth;

                // then the hover
                $('.timelinePeriod').hover(
                    // in
                    function() {
                        periodTitleWidth = $(this).find('.periodTitle').width();
                        connectLineWidth = $(this).find('.connectLine.ending.last').width();
                        if (periodTitleWidth < 100 ) {
                            $(this).find('.periodTitle').addClass('expand-timeline-period');
                            newWidth = $(this).find('.periodTitle').width();
                            $(this).find('.connectLine.ending.last').css('width',(newWidth+20));
                        }
                    },
                    // out
                    function() {
                        $(this).find('.periodTitle').removeClass('expand-timeline-period');
                        $(this).find('.connectLine.ending.last').css('width',(connectLineWidth));
                    }
                );

            }
        }
    })
});

