define('modules/finalists/expander/js/base.js',['app/config', 'jquery', 'app/util', 'templates', 'skrollr', 'waypoints', 'jquery.mobile.custom'], function (config, $, util, templates, skrollr) {
    return {
        name: 'finalists',
        selectors: '.finalistContainer',
        remoteData: [config.urls.finalists],
        data: [],

        config: {},

        routes: [
            {
                route: 'finalists/(.*)',
                fn: function (hash) {
                    this.goToHash2(hash);
                },
                extra: true,
                delay: 2000
            }],
        initialize: function (data) {
            var initHtml;

            this.$container = $(this.selectors);
            this.data = (data || this.data);
            this.renderTemplates(this.data);
            this.finalistOrginalData(this.data);

            $('body').trigger('pagenavupdate');


        },

        // TODO add back in close expander hash

        goToHash2: function (hash) {
            var scrollToTeam = hash.split('/')[1];

            $(".intro-section-btn[data-scrollto='.section.finalists']").trigger('click');

            var finalistItem = $('.finalist-post[data-teamid="' + scrollToTeam + '"]');
            if (finalistItem) {

                var innerCol = finalistItem.find('.inner-col');
                $(innerCol).trigger('tap');

            }
        },

        renderTemplates: function (data) {

            $.each(data, function (i, r) {
                data[i].total = data.length;
            });


            $(this.selectors).append(templates.finalists({
                steps: data
            }));

            var totalIndex = 0;

            for(var i = 0; i < data.length; i++) {
                if (data[i].honorablemention == 'false') {
                    totalIndex++;
                }
            }

            $('.expanderNav .total-count').html(totalIndex);


            this.$container.on('click', '.finalist-post', $.proxy(function (event) {

                var $target = $(event.target);

                if (!$target.hasClass("finalist-post")) {
                    $target = $target.parents(".finalist-post");
                }

                var index = $target.attr('data-id');

                $('.expander-counts .current-count').html(index);

                var innerSwap = $target.find('.innerSwap').html();

                this.$container.find('.openedFinalist').removeClass('openedFinalist');

                $target.addClass('openedFinalist');

                var finalistColor = $target.attr('data-color');
                this.$container.find('.expanderNav .expander-close i').css('color', finalistColor);

                this.$container.find('.expanderContent').html(innerSwap).slideDown(200);


                if (!this.$container.find('expanderNav').hasClass('shown')) {
                    this.$container.find('.expanderNav').addClass('shown');
                }

                var parentRow = $target.parents('.row').first();

                $target.parents('.eGrid').find('.detailRow').insertAfter(parentRow).fadeIn();

                $('html, body').animate({
                    scrollTop: $(this.selectors).find('.expanderContent').offset().top - 100
                }, 500, function () {
                    $(window).trigger('resize');
                });


                if ($(window).width() < 768) {
                    $('body').addClass('locked');
                    $('.navbarWrap').addClass('hidden');
                }


            }, this));

            this.$container.find('.expanderNav .prev').on('click', $.proxy(function () {

                var currentActive = this.$container.find('.openedFinalist').data('id');
                var prevPost = currentActive - 1;

                if (currentActive == 1) {
                    $('.finalist-post[data-id="' + totalIndex + '"]').trigger('click');
                }
                else {
                    $('.finalist-post[data-id="' + prevPost + '"]').trigger('click');
                }
            }, this));

            this.$container.find('.expanderNav .next').on('click', $.proxy(function () {

                var currentActive = this.$container.find('.openedFinalist').data('id');
                var nextPost = currentActive + 1;

                if (currentActive == totalIndex) {
                    $('.finalist-post[data-id="1"]').trigger('click');
                }
                else {
                    $('.finalist-post[data-id="' + nextPost + '"]').trigger('click');
                }

            }, this));

            this.$container.find('.expanderNav .close').on('click', $.proxy(function () {

                this.$container.find('.openedFinalist').removeClass('openedFinalist');
                this.$container.find('.expanderContent').slideUp(400, function () {
                    $(this).html('');
                });

                this.$container.find('.detailRow').fadeOut();

                this.$container.find('.expanderNav').removeClass('shown');

                if ($(window).width() < 768) {
                    $('body').removeClass('locked');
                    $('.navbarWrap').removeClass('hidden');
                }

                $('html, body').animate({
                    scrollTop: $(this.selectors).offset().top - 100
                }, 500, function () {
                    $(window).trigger('resize');
                });

            }, this));

        },

        finalistOrginalData: function (data) {
            initHtml = $('.finalists.eGrid').html();
        },

    }
});

