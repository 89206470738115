define('app/util',['jquery'],function($){

    // Custom jQuery methods
    $.fn.serializeObject = function()
    {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function() {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    $.fn.nextOrFirst = function(selector) {
        var next = this.next(selector);
        return (next.length) ? next : this.prevAll(selector).last();
    };

    $.fn.prevOrLast = function(selector) {
        var prev = this.prev(selector);
        return (prev.length) ? prev : this.nextAll(selector).last();
    };

    // Util Methods accessed across the app
    return {

        // Provides easy access to manifest build configuration
        buildConfig: null,
        setBuildConfig: function(config) {
            this.buildConfig = config;
        },
        getBuildConfig: function() {
            return this.buildConfig;
        },

        minScrollSpeedTracker: {
            lastOffset: null,
            lastTimestamp: null
        },

        // Offers a native object extend method
        extendObj: function(obj,obj2) {
            for (var i in obj) {
                if (obj.hasOwnProperty(i)) {
                    obj2[i] = obj[i];
                }
            }
            return obj2;
        },

        // Better URI component method - http://stackoverflow.com/questions/901115/get-querystring-values-with-jquery/901144#901144
        getParameterByName: function(name) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(location.search);
            return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        },

        // Helper method to get the active "page" name
        getActivePageTitle: function() {
            if($('.pageContainer.active').length > 0 && $('.pageContainer.active').attr('data-title').length > 0) {
                return $('.pageContainer.active').attr('data-title');
            } else {
                return 'Home'; // TODO - Determine the proper fallback title
            }
        },

        // Data sorting methods
        dynamicSort: function(property) {
            return function (obj1,obj2) {
                return obj1[property] > obj2[property] ? 1
                    : obj1[property] < obj2[property] ? -1 : 0;
            }
        },

        dynamicSortMultiple: function() {
            var props = arguments;
            return function (obj1, obj2) {
                var i = 0, result = 0, numberOfProperties = props.length;
                while(result === 0 && i < numberOfProperties) {
                    result = this.dynamicSort(props[i])(obj1, obj2);
                    i++;
                }
                return result;
            }
        },

        lockScrolling: function(state, els) {
            if(state) {
                var selScrollable = els;
                $(document).on('touchmove.lockScrolling',function(e){
                    e.preventDefault();
                });
                $('body').on('touchstart.lockScrolling',selScrollable, function(e) {
                    if (e.currentTarget.scrollTop === 0) {
                        e.currentTarget.scrollTop = 1;
                    } else if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
                        e.currentTarget.scrollTop -= 1;
                    }
                });
                $('body').on('touchmove.lockScrolling', selScrollable, function(e) {
                    e.stopPropagation();
                });
            } else {
                $(document).off('touchmove.lockScrolling');
                $('body').off('touchmove.lockScrolling touchstart.lockScrolling');
            }
        },
        // Basic email validation
        isEmail: function(email) {
            var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            return regex.test(email);
        },
        // Scrolls an element to the top of the page
        // Takes a second param as an offset vertical amount (number of pixels, percent for viewport height)
        // Takes third param as optional duration, defaults to 1000ms
        scrollElToTop: function(element,offset,duration) {
            if(typeof offset == 'string' && offset.indexOf('%') > -1) {
                offset = (parseFloat(offset)/100)*$(window).height();
            }
            var scrollParent = $(element).scrollParent();
            if($(scrollParent).is('body') || $(scrollParent).is('html')) {
                scrollParent = $('html,body');
            } else {
                scrollParent = $($(element).scrollParent() || 'html,body');
            }
            if (element) {
                if($('body').hasClass('spapp')) {
                    $('.pageContainer:visible').animate({
                        scrollTop: ($('.pageContainer:visible').scrollTop()+$(element).offset().top)+(offset || 0)
                    }, (duration || 1000));
                } else {
                    scrollParent.animate({
                        scrollTop: $(element).position().top+(offset || 0)+scrollParent.scrollTop()
                    }, (duration || 1000));
                }
            } else {
                this.scrollBodyToTop((duration || 1000));
            }
        },
        // Scrolls the entire page to the top
        // Duration defaults to 500ms if not specified
        scrollBodyToTop: function(duration,forceShowNavbar) {
            forceShowNavbar = (typeof forceShowNavbar === 'undefined' ? true : forceShowNavbar );
            $($('body').hasClass('spapp') ? '.pageContainer:visible' : 'html, body').animate({
                scrollTop: -1
            }, (duration === undefined ? 500 : duration));
            if(forceShowNavbar) {
                $('body').trigger('navbartoggle',true);
            }
        },
        preventOverscroll: function(els,state) {
            state = (state == undefined ? true : false);
            var fn = function(e) {
                var scrollTo = null;
                if (e.type == 'mousewheel') {
                    scrollTo = (e.originalEvent.wheelDelta * -1);
                } else if (e.type == 'DOMMouseScroll') {
                    scrollTo = 40 * e.originalEvent.detail;
                }
                if (scrollTo) {
                    e.preventDefault();
                    $(this).scrollTop(scrollTo + $(this).scrollTop());
                }
            };
            if(state) {
                $(els).on('mousewheel.preventOverscroll DOMMouseScroll.preventOverscroll',fn);
            } else {
                $(els).off('mousewheel.preventOverscroll DOMMouseScroll.preventOverscroll');
            }
        },

        checkScrollSpeed: function(newDelta,settings){
            settings = settings || {};
            var timer,
                delta;
            var delay = settings.delay || 50;
            var clear = function() {
                delta = 0;
            }
            clear();
            delta += newDelta;
            clearTimeout(timer);
            timer = setTimeout(clear, delay);
            return isNaN(delta) ? 0 : delta;
        },

        minScrollSpeed: function(threshold,fn) {
            var ut = this;
            if(typeof threshold == 'function') {
                fn = threshold;
                threshold = 20;
            }
            return function(event,delta) {
                if(event.type == 'scroll') {
                    ut.minScrollSpeedTracker.lastOffset = (ut.minScrollSpeedTracker.lastOffset || $(event.target).scrollTop())
                    ut.minScrollSpeedTracker.lastTimestamp = (ut.minScrollSpeedTracker.lastTimestamp || new Date().getTime());
                    var delayInMs = event.timeStamp - ut.minScrollSpeedTracker.lastTimestamp;
                    var offset = $(event.target).scrollTop() - ut.minScrollSpeedTracker.lastOffset;
                    var speed = offset / delayInMs;
                    ut.minScrollSpeedTracker.lastTimestamp = event.timeStamp;
                    ut.minScrollSpeedTracker.lastOffset = $(event.target).scrollTop();
                    if (speed > 0.35 || speed < -(0.35)) {
                        fn(arguments);
                    }
                } else {
                    if (ut.device.safari() && !ut.device.mobile()) {
                        var deltaThreshold = 1+(threshold*0.001);
                        var deltaSpeed = -(($(window).scrollTop() / $(document).height()) * 110);
                    } else {
                        var deltaThreshold = threshold;
                        var deltaSpeed = ut.checkScrollSpeed(event.originalEvent.wheelDelta || delta);
                        if (!event.originalEvent.wheelDelta) {
                            deltaThreshold = deltaThreshold / 26;
                        }
                    }
                    if (deltaSpeed > deltaThreshold || deltaSpeed < -(deltaThreshold)) {
                        fn(arguments);
                    }
                }
            };
        },

        // Scrolls an inner scrolling element to the bottom
        scrollElToBottom: function(el,distance) {
            if(!distance) {
                $(el).scrollTop($(el)[0].scrollHeight-$(el).outerHeight());
            }
        },

        // TODO - Depracted, move into spinners feature
        setLoading: function(state,message,element) {
            // TO-DO - Custom loading mask function
            if(state) {
                $(element || 'body').append($('<div class="loadingMask"><div class="loading-gif-wrap"><img src="../img/loading.gif"/></div><div class="loadingMessage">Loading...</div></div>'));
            } else {
                $(element || 'body').find('.loadingMask').remove();
            }
            if(message) {
                $(element || 'body').find('.loadingMessage').text(message);
            }
        },

        bootstrapNumberCarousel: function(element) {
            // This is a customization specific to projects for the bootstrap carousel, eventually we want to relocate this
            
            // TODO added element to specify which carousel
            // possibly want to rework this
			if (element) {
				$( document ).ready(function() {
	
					// adding some functionality for dynamic lis
	
					var itmCt = 1;
					$(''+element+' .carousel-inner .item').each(function(){
						$('.carousel-indicators').append('<li data-target="'+element+'" data-slide-to="'+(itmCt - 1)+'">'+itmCt+'</li>');
						itmCt++;
					});
	
					$( '.carousel-indicators li:first-child').addClass('active');

					var liCt = 0;
					$( ".carousel-indicators li" ).each(function() {
						liCt++;
					});

					if (liCt == 1) {
						$('.caro-nav-container').remove();
					}
					else {
						$('.caro-count .count').text(liCt);
					}
				});
            }
        },

        // TODO - Deprecated and to be moved down to user agent testing methods
        isSafari: function() {
            var uagent = navigator.userAgent.toLowerCase();
            return /safari/.test(uagent) && /applewebkit/.test(uagent) && !/chrome/.test(uagent);
        },
        // TODO - Deprecated and to be moved down to user agent testing methods
        isSafari7: function() {
            if(this.isSafari()) {
                if(navigator.userAgent.toLowerCase().indexOf('/7.0') > -1) {
                    $('html').addClass('ios7');
                    return true;
                }
            }
            return false;
        },

        // User agent checking methods
        // TODO - Add further refinement
        device: {
            android: function() {
                return /Android/i.test(navigator.userAgent);
            },
            blackberry: function() {
                return /BlackBerry/i.test(navigator.userAgent);
            },
            ios: function() {
                return /iPhone|iPad|iPod/i.test(navigator.userAgent);
            },
            mobile: function() {
                return (this.android() || this.blackberry() || this.ios());
            },
            safari: function() {
                return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
            },
            firefox: function() {
                return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            }
        },

        // Helper class for waypoint handling
        // Allows you to create and start/stop batches of waypoints
        waypointHandler: function() {
            return {
                wps: [],
                clear: function() {
                    this.wps = [];
                },
                add: function(wps) {
                    this.wps = this.wps.concat(wps || []);
                },
                pause: function() {
                    $(this.wps).each(function(){
                        this.disable();
                    });
                },
                resume: function(delay) {
                    if(delay) {
                        setTimeout($.proxy(function(){$(this.wps).each(function(){
                            this.enable();
                        });},this),(delay || 0));
                    } else {
                        $(this.wps).each(function(){this.enable();});
                    }
                }
            }
        },

        // Generates random RFC4122 complaint UIDs
        generateUID: function() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
                return v.toString(16);
            });
        },

        // Calls a Prizeware (typically) endpoint with standarized handling
        callEndpoint: function(name,payload,callback,onProgress) {
            callback = (callback || $.noop);
            onProgress = (onProgress || $.noop);
            $.ajax({
                type: "POST",
                url: "/endpoint.aspx/"+name,
                data: JSON.stringify(payload),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                async: true,
                success: function (result) {
                    callback(JSON.parse(result.d));
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.debug(xhr.status,xhr.responseText,thrownError);
                    // TODO - Prioritize and capture additional info through TrackJS
                },
                xhr: function() {
                    var xhr = new window.XMLHttpRequest();
                    xhr.addEventListener("progress", function(evt) {
                        if (evt.lengthComputable) {
                            onProgress((evt.loaded / evt.total)*100);
                        }
                    }, false);
                    return xhr;
                }
            });
        },

        parseYoutubeId: function(value) {
            var rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
            var r = value.match(rx);
            try {
                if (r != null && r[1].length == 11) {
                    return r[1];
                }
            }
            catch (e) {
            }
            return value;
        },

        unique: function(array) {
            return $.grep(array, function(el, index) {
                return index == $.inArray(el, array);
            });
        },

        trackError: function(error) {
            window.trackJs && window.trackJs.track(error);
        },

        getEmptyPixelSrc: function() {
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
        },

        // Record Methods
        // TODO - Move these to another util class
        groupRecords: function(data,groupKey,pick) {
            var values = data.reduce(function(obj,item){
                obj[item[groupKey]] = obj[item[groupKey]] || [];
                obj[item[groupKey]].push(item);
                return obj;
            }, {});
            var g = Object.keys(values).map(function(key){
                return {group: key, records: values[key]};
            });
            return (pick ? $.grep(g,function(d){
                return (d.group == pick);
            })[0].records : g);
        },

        getRandomRecords: function(records,count) {
            var result = [];
            var tmp = records.slice();
            for(var i = 0; i<count; i++){
                var index = Math.ceil(Math.random() * 10) % tmp.length,
                    iv = tmp.splice(index, 1)[0];
                if(iv) {
                    result.push(iv);
                } else {
                    break;
                }
            }
            return result;
        },

        checkElementOverlap: function(e1,e2) {
            if($(e1).get().length == 0 || $(e2).get().length == 0) {
                return false;
            }
            e1 = $(e1).get()[0].getBoundingClientRect();
            e2 = $(e2).get()[0].getBoundingClientRect();
            return !(e1.right < e2.left ||  e1.left > e2.right || e1.bottom < e2.top || e1.top > e2.bottom);
        },

        setVisibleInterval: function(el,callback,duration) {
            duration = (duration || 5000);
            var interval = setInterval(function(){
                if($(el).is(':visible')) {
                    clearInterval(interval);
                    callback();
                }
            },duration);
        }

    }
});
