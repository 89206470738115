define('modules/faqs/accordion/js/base.js',["app/module","app/config","jquery","app/util","templates","swiper.jquery.min","jquery.livequery"], function(module,config,$,util,templates) {
    return new module({
        name: 'faqs.accordion',
        selectors: '.faqWrap',
        remoteData: [(config.urls.faqs)],
        
        config: {
            
        },

        routes: [{
            route: 'about',
            extra: true
        }],

        initialize: function (data) {
            this.data = (data || this.data);
            this.renderTemplates(this.data);
            $('#faqAccordion').collapse();
            $('#faqAccordion .panel-group').removeClass('collapsed in')
        },

        onRender: function() {
            //this.clickHandlers(this.data);
            //util.setVisibleInterval($(this.getSelectors()),$.proxy(this.initSwiper,this));  // Swiper will not properly initialize until the element is actually visible on DOM, hence this interval
        },

		methods: {
			renderTemplates: function (data) {
				$.each(data, function (i, r) {
					data[i].total = data.length; // TODO - Not necessary, we can access parent context length directly in handlebars tpl via dot notation
				});
				$(this.getSelectors()).append(templates.faqsaccordion({
					records: data.sort(function(a, b) {
                        return a.id - b.id;
                    })
				}));
			}
		}
	});
});

