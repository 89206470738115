/*
 * This is the effect that adds a fixed parallax background
 */
define('modules/animations/parallaxbg/js/base.js',["app/module","app/config","jquery","app/util","parallax"], function(module,config,$,util,parallax) {
    return new module({
        name: 'parallaxbg',
        selectors: false,
        remoteData: [],

        // Fixed mode - if set to true, one element will be created and the image updated from triggered events
        // If set to false, multiple parallax tpls are immediately created and inserted into each section
        // By setting data attribute for "bgimage" without a string instead as true, a default will be used
        config: {
            tpl: '<ul class="fixedBg pxBg" data-pxscroll="-0.8" data-pxscrollmode="background"><li class="layer" data-depth="0.2"></li></ul>',
            tplGradient: '<li class="layer pxGradient" data-depth="0.2"></li>',
            fixed: false,
            mouseParallax: false
        },

        initialize: function() {

            if(this.getConfig().fixed) {
                // Add an event listener for changing parallax images
                $('body').on('parallaxbgupdate', $.proxy(this.updateParallaxImage,this));
                $('body').prepend($(this.getConfig().tpl)); // Add the parallax container
                if(!util.device.mobile() && this.getConfig().mouseParallax) {
                    $('.fixedBg').each(function(){
                        var pxInstance = new parallax($(this).get(0));
                    });
                }
            } else {
                $('body').livequery('*[data-bgimage]',$.proxy(function(el){
                    this.insertParallaxContainer(el);
                    $(el).expire();
                },this));
            }

            // Generic parallax containers with layers
            $('body').livequery('*[data-pxcontainer]',$.proxy(function(el) {
                if(util.device.mobile() && $(el).find('*[data-pxnomobile]').length > 0) {
                    $(el).find('*[data-pxnomobile]').attr('data-depth',0);
                }
                if(this.getConfig().mouseParallax) {
                    var pxInstance = new parallax($(el).get(0));
                }
                $(el).expire();
            },this));
        },

        methods: {
            updateParallaxImage: function (name) {
                // TODO - Implement this for fixed set to false
            },

            insertParallaxContainer: function (el) {
                var tpl = ($(this.getConfig().tpl) || ''),
                    gradientTpl = null;
                    
                if ($(el).attr('data-bgimage').indexOf('png') > -1 ) {
                	$(tpl).children().first().css('background-image', 'url(img/parallax/' + ($(el).attr('data-bgimage').length > 0 ? $(el).attr('data-bgimage') : 'default') + ')');
                }
                else {    
                	$(tpl).children().first().css('background-image', 'url(img/parallax/' + ($(el).attr('data-bgimage').length > 0 ? $(el).attr('data-bgimage') : 'default') + '.jpg)');
                }
                $(el).prepend(tpl);
                if($(el).attr('data-bggradient')) {
                    gradientTpl = ($(this.getConfig().tplGradient) || '');
                    $(tpl).append(gradientTpl);
                }
                if(!util.device.mobile() && this.getConfig().mouseParallax) {
                    var pxInstance = new parallax($(tpl).get(0));
                }
            },

            // Legacy Method - not currently used
            // TODO - Implement this as a module option if future designs ever require it
            randomizeImages: function () {
                // Parallax (mouse)
                // Random the selection for non-home images
                /*$('.pxRand').each(function () {
                 var random = Math.ceil(Math.random() * 3);
                 $(this).find('.layerRand').each(function (layerIndex, layerEl) {
                 $(layerEl).css('background-image', $(layerEl).css('background-image').replace('parallax_1', 'parallax_' + random));
                 });
                 });*/
            }
        }
    });
});
