define('app/testing',['jquery','app/util'],function($,util){
    return {
        init: function() {
            //>>excludeStart("production",pragmas.production);
            if(util.getBuildConfig() && util.getBuildConfig()['buildTime']) {
                // $('body').append('<div style="z-index:2147483636;position:fixed;bottom:0px;right:30px;text-align:right;background:rgba(52,73,94,0.5);color:white;font-size:10px;line-height:10px;padding:3px;">Last Build: '+new Date(util.getBuildConfig().buildTime).toLocaleString()+'</div>');
            }
            //>>excludeEnd("production");
        }
    };
});
